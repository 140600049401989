#authorise-expensive {
    .ant-table {
        .ant-table-tbody {
            .ant-table-row-selected {
                .ant-table-cell {
                    background: yellow;
                }
            }
        }
    }
}