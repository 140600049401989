.group-permission-form {
    table {
        width: 100%;
        thead {
            th {
                padding: 10px 16px;
                border-bottom: 1px solid #f7f5f3;
                border-right: 1px solid #f7f5f3;
                font-weight: 700;
                font-size: 12px;
                color: #8a8988;

                &:nth-child(2) {
                    text-align: center;
                }
            }
        }
        tbody {
            td {
                padding: 10px 16px;
                border-bottom: 1px solid #f7f5f3;
                border-right: 1px solid #f7f5f3;
                font-weight: 400;
                font-size: 14px;
                color: #1e1d1d;

                &:nth-child(2) {
                    text-align: center;
                }
            }
        }
    }
}
