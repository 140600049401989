#send-message {
  .sticky-search {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  .message-list {
    overflow: hidden;
  }

  .message-hover:hover {
    background: #eee;
    cursor: pointer;
  }

  .message-selected {
    color: #0F4C85;
    background-color: #C2D0E7;
    cursor: pointer;
  }

  .message-content {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .message-content-wrapper .message-content-container {
    background-color: #0F4C85;
    padding: 4px;
    color: #ffffff !important;
  }
}

#custom-upload {
  .ant-upload-list.ant-upload-list-text {
    background: white;
    position: absolute;
    bottom: 150px;
    width: 100%;
    left: 0;
  }
  .ant-upload-list-item.ant-upload-list-item-done {
    border-top: 1px solid var(--Neutral-100, #dcdde5) !important;
  }

  .ant-upload.ant-upload-drag {
    border: 2px dashed #0F4C85 !important;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload  {
    padding: 12px 0;
  }
}
