.login-img {
  background-image: url('/src/assets/images/login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  flex: 1;
}

.login-logo-container {
  height: 120px;
  width: 100%;
  background-image: url('/src/assets/images/azure-logo.png');
  background-size: 40%, 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.signup-img {
  background-image: url('/src/assets/images/signup.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.auth-left {
  width: 600px;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.auth-right {
  width: 100%;
  height: 100%;
}
